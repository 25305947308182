import { template as template_c133a946f86c4346a26a0fa97fba76a3 } from "@ember/template-compiler";
const FKLabel = template_c133a946f86c4346a26a0fa97fba76a3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
