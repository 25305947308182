import { template as template_10a5ee2446e9472889323dd5fe3b12fd } from "@ember/template-compiler";
import Component from "@glimmer/component";
import icon from "discourse-common/helpers/d-icon";
export default class FKErrors extends Component {
    concatErrors(errors) {
        return errors.join(", ");
    }
    static{
        template_10a5ee2446e9472889323dd5fe3b12fd(`
    <p class="form-kit__errors" id={{@id}} aria-live="assertive" ...attributes>
      <span>
        {{icon "triangle-exclamation"}}
        {{this.concatErrors @error.messages}}
      </span>
    </p>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
