import { template as template_209c2ff518c642cd8e06e433d7f801ca } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_209c2ff518c642cd8e06e433d7f801ca(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
